
import Vue from "vue";
import Component from "vue-class-component";
import DynamicTitle from "@/components/DynamicTitle";
import BaseButton from "@/components/BaseButton";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import BaseDialog from "@/components/BaseDialog";
import { Routes } from "@/router/routes";
import { UserGetters } from "@/store/modules/user/getters";
import { RoleGetters } from "@/store/modules/role/getters";
import { User } from "@/models/users/User";
import { DataTableHeader } from "vuetify";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserActions } from "@/store/modules/user/actions";
import { RoleActions } from "@/store/modules/role/actions";

@Component({
    components: { BaseButton, DynamicTitle, ConfirmationDialog, BaseDialog },
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users,
            loading: UserGetters.Loading
        }),
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        })
    },
    methods: {
        ...userModule.mapActions({
            deleteUserById: UserActions.DeleteUserById,
            getTwoFactorRecoveryCode: UserActions.GetTwoFactorRecoveryCode,
            loadUsers: UserActions.LoadUsers
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        })
    }
})
export default class Users extends Vue {
    protected readonly users!: User[];
    protected readonly loading!: boolean;
    protected search = "";

    private readonly loadUsers!: () => Promise<void>;
    private readonly deleteUserById!: (userId: number) => Promise<void>;
    private readonly getTwoFactorRecoveryCode!: (
        userId: number
    ) => Promise<string>;

    protected readonly headers: DataTableHeader[] = [
        {
            text: "First name",
            value: "firstName",
            sortable: true,
            filterable: true
        },
        {
            text: "Last name",
            value: "lastName",
            sortable: true,
            filterable: true
        },
        { text: "Email", value: "email", sortable: true, filterable: true },
        { text: "Role", value: "role", sortable: true, filterable: true },
        {
            text: "BMW Group Abbreviation",
            value: "bmwGroupAbbreviation",
            sortable: true,
            filterable: true
        },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    userForAction: User | null = null;
    userTwoFactorCode = "";
    deleteDialog = false;
    twoFactorDialog = false;

    get deleteMessage(): string {
        return `Are you sure you want to delete ${this.userForAction?.email}?`;
    }

    get twoFactorMessage(): string {
        return `Two factor emergency code for ${this.userForAction?.email}:
            ${this.userTwoFactorCode}`;
    }

    created(): void {
        this.loadUsers();
    }

    addNewUser(): void {
        this.$router.push(Routes.CreateUser);
    }

    editUser(user: User): void {
        this.$router.push(Routes.EditUser + user.id);
    }

    showDeleteDialog(user: User): void {
        this.userForAction = user;
        this.deleteDialog = true;
    }

    async deleteUser(): Promise<void> {
        if (this.userForAction)
            await this.deleteUserById(this.userForAction.id);
    }

    async showTwoFactorDialog(user: User): Promise<void> {
        this.userTwoFactorCode = await this.getTwoFactorRecoveryCode(user.id);
        this.userForAction = user;
        this.twoFactorDialog = true;
    }
}
