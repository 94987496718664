
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import { userModule } from "@/store/modules/user";
import UserEditor from "@/components/UserEditor";
import { UserActions } from "@/store/modules/user/actions";
import { UserGetters } from "@/store/modules/user/getters";
import { UserMutations } from "@/store/modules/user/mutations";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import Errors from "./BackendResponseErrors.json";
import DynamicTitle from "@/components/DynamicTitle";
import { roleModule } from "@/store/modules/role";
import { RoleActions } from "@/store/modules/role/actions";
import { User } from "@/models/users/User";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { UserEditor, DynamicTitle },
    computed: {
        ...userModule.mapGetters({
            isEdited: UserGetters.IsEdited
        })
    },
    methods: {
        ...userModule.mapActions({
            commitCreateUser: UserActions.CreateUser
        }),
        ...userModule.mapGetters({
            getErrors: UserGetters.Errors
        }),
        ...userModule.mapMutations({
            setErrors: UserMutations.SetErrors
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        })
    }
})
export default class CreateUser extends Vue {
    private readonly commitCreateUser!: (user: Partial<User>) => Promise<void>;
    private readonly getErrors!: () => string[] | null;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly loadRoles!: () => Promise<void>;

    protected readonly roles!: string[];

    private readonly isEdited!: boolean;

    protected errorSnackbar = false;
    protected errors = "";

    private leaveConfirmationEnabled = true;

    protected readonly titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Users },
        { name: "Create New User", selected: true }
    ];

    $refs!: {
        form: VuetifyForm;
    };

    created(): void {
        if (!this.roles) {
            this.loadRoles();
        }
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    async createUser(user: Partial<User>): Promise<void> {
        await this.commitCreateUser(user);
        const errors = this.getErrors();
        this.setErrors(null);

        if (errors) {
            const validationErrors = errors.filter((e) =>
                e.includes(Errors.ValidationProblem.Error)
            );

            if (errors.find((e) => e.includes(Errors.DuplicateEmail.Error))) {
                this.errors = Errors.DuplicateEmail.Message;
            } else if (validationErrors?.length) {
                this.errors = validationErrors
                    .map((error) => {
                        return error.substr(error.search("message:") + 8);
                    })
                    .join(" ");
            } else {
                this.errors = Errors.Default.Message;
            }
            this.errorSnackbar = true;
        } else {
            this.leaveConfirmationEnabled = false;
            this.$router.push(Routes.Users);
        }
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
