
import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/models/users/User";
import { roleModule } from "@/store/modules/role";
import { RoleGetters } from "@/store/modules/role/getters";
import { canUserManageRole } from "@/services/userUtils";
import { Prop } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton";
import validationRules from "@/services/validationRules";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { Market } from "@/models/markets/Market";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { BaseButton },
    computed: {
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        }),
        ...marketModule.mapGetters({
            regions: MarketGetters.Regions,
            markets: MarketGetters.Markets
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadRegions: MarketActions.LoadRegions,
            loadMarkets: MarketActions.LoadMarkets
        })
    }
})
export default class UserEditor extends Vue {
    private readonly loadRegions!: () => Promise<void>;
    private readonly loadMarkets!: () => Promise<void>;

    protected readonly regions!: string[];
    protected readonly markets!: Market[];

    @Prop({
        type: Object,
        default: () => ({
            firstName: "",
            lastName: "",
            email: "",
            role: "",
            markets: []
        })
    })
    public readonly user!: User;

    @Prop({ type: Boolean, default: false })
    public isEdit!: boolean;

    @Prop({ type: Boolean, default: false })
    public isLoading!: boolean;

    protected readonly roles!: string[];

    private readonly marketRoles = ["BMW Board Case Reader", "Market writer"];

    $refs!: {
        form: VuetifyForm;
    };

    get availableRoles(): string[] {
        return this.roles.filter((r) => canUserManageRole(r));
    }

    get isMarketRole(): boolean {
        return this.marketRoles.includes(this.user.role);
    }

    protected readonly firstNameRules = [
        validationRules.required("First Name"),
        validationRules.minLength(2, "First Name"),
        validationRules.maxLength(30, "First Name")
    ];
    protected readonly lastNameRules = [
        validationRules.required("Last Name"),
        validationRules.minLength(2, "Last Name"),
        validationRules.maxLength(30, "Last Name")
    ];
    protected readonly emailRules = [
        validationRules.required("Email"),
        validationRules.email()
    ];

    get buttonText(): string {
        return this.isEdit ? "Edit User" : "Create New User";
    }

    get buttonIcon(): string {
        return this.isEdit ? "edit" : "add";
    }

    get countryCodes(): string[] {
        return this.markets.map((m) => m.countryCode);
    }

    created(): void {
        this.loadRegions();
        this.loadMarkets();
        if (this.user) {
            const markets = this.markets.filter((m) =>
                this.user.markets?.includes(m.countryCode)
            );
            this.selectedRegions = [...new Set(markets.map((m) => m.region))];
        }
    }

    submit(): void {
        const validationResult = this.$refs.form.validate();
        if (validationResult) {
            this.$emit("submit", this.user);
        }
    }

    regionChanged(regions: string[]): void {
        this.user.markets = [];
        regions.forEach((r) => {
            this.markets.forEach((m) => {
                if (m.region === r) {
                    this.user.markets?.push(m.countryCode);
                }
            });
        });
    }

    get selectedRegions(): string[] {
        const markets = this.markets.filter((m) =>
            this.user.markets?.includes(m.countryCode)
        );
        return [...new Set(markets.map((m) => m.region))];
    }
    set selectedRegions(regions: string[]) {
        this.user.markets = [];
        regions.forEach((r) => {
            this.markets.forEach((m) => {
                if (m.region === r) {
                    this.user.markets?.push(m.countryCode);
                }
            });
        });
    }
}
